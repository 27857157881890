<template>
  <section class="workflow">
    <div class="workflow__container">
      <div class="workflow__container__item">
        <h3>Create</h3>
        <p>Drag ‘n drop components onto the canvas from our component panel</p>
        <div class="workflow__container__img__box">
          <img src="@/assets/images/home/workflow/create.png" alt="" />
        </div>
      </div>
      <div class="workflow__container__item">
        <h3>Edit</h3>
        <p>Design and modify your email with our intuitive edit panel</p>
        <div class="workflow__container__img__box">
          <img src="@/assets/images/home/workflow/edit.png" alt="" />
        </div>
      </div>
      <div class="workflow__container__item">
        <h3>Export</h3>
        <p>Connect and export directly to MailChimp, Gmail, HubSpot, etc</p>
        <div class="workflow__container__img__box">
          <img src="@/assets/images/home/workflow/export.png" alt="" />
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "WorkflowComponent",
});
</script>

<style scoped></style>
