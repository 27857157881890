<template>
  <footer class="footer">
    <div class="footer__container">
      <div class="footer__cta">
        <h6>Let’s stay in touch</h6>
        <div class="footer__cta__actions">
          <button>
            <BaseIcon icon="home/footer/cta/x" />
          </button>
          <button>
            <BaseIcon icon="home/footer/cta/instagram" />
          </button>
          <button>
            <BaseIcon icon="home/footer/cta/discord" />
          </button>
          <button>
            <BaseIcon icon="home/footer/cta/slack" />
            <span
              >Join our Slack <br />
              community</span
            >
          </button>
        </div>
        <p>or send us an email at <a href="">hello@drawberry.io</a></p>
      </div>
      <div class="footer__imprint">
        <BaseIcon icon="home/footer/berrycon" />
        <h6>Made with 💚 from the Berryverse™ 🌈</h6>
        <p>© 2024 Drawberry. All rights reserved</p>
        <div class="footer__imprint__img__box">
          <img src="@/assets/images/home/footer/polaroid-cards.png" alt="" />
        </div>
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import BaseIcon from "@/components/icon/BaseIcon.vue";

export default defineComponent({
  name: "FooterComponent",
  components: { BaseIcon },
});
</script>

<style scoped></style>
