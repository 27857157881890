import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "hero" }
const _hoisted_2 = { class: "hero__container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SliderComponent = _resolveComponent("SliderComponent")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "hero__content" }, [
        _createElementVNode("h1", null, "Stand out in a cluttered inbox"),
        _createElementVNode("p", null, " Discover our easy-to-use tool for designing engaging emails for your marketing campaigns and newsletters. "),
        _createElementVNode("a", { href: "" }, "Get early access")
      ], -1)),
      _createVNode(_component_SliderComponent)
    ])
  ]))
}