<template>
  <div class="hero__slider">
    <div class="slider-track">
      <div class="slider-inner">
        <div v-for="n in imageCount" :key="n" class="slider-image-wrapper">
          <img
            :src="require(`@/assets/images/home/hero/sliders/${n}.png`)"
            :alt="`Slide ${n}`"
            class="slider-image"
          />
        </div>
      </div>
      <div class="slider-inner" aria-hidden="true">
        <div
          v-for="n in imageCount"
          :key="`duplicate-${n}`"
          class="slider-image-wrapper"
        >
          <img
            :src="require(`@/assets/images/home/hero/sliders/${n}.png`)"
            :alt="`Slide ${n}`"
            class="slider-image"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "SliderComponent",
  props: {
    imageCount: {
      type: Number,
      default: 13,
    },
    animationDuration: {
      type: Number,
      default: 40,
    },
  },
});
</script>

<style scoped>
.hero__slider {
  width: 100%;
  overflow: hidden;
  margin-top: 20px;
  height: 400px;
}

@media (max-width: 630px) {
  .hero__slider {
    height: 350px;
  }
}

.slider-track {
  display: flex;
  width: 100%;
}

.slider-inner {
  display: flex;
  gap: 14px;
  animation: slide v-bind('animationDuration + "s"') linear infinite;
}

@media (max-width: 630px) {
  .slider-inner {
    gap: 7px;
  }
}

.slider-image-wrapper {
  width: 208px;
  //height: 180px;
  display: flex;
  align-items: center;
  margin-right: 14px;
  //justify-content: center;
}

@media (max-width: 630px) {
  .slider-image-wrapper {
    margin-right: 7px;
  }
}

.slider-image {
  width: 100%;
  //height: auto;
  //object-fit: contain;
  //object-position: center;
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

/* Pause animation on hover for better user experience */
.slider-track:hover .slider-inner {
  animation-play-state: paused;
}
</style>
