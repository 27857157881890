
import { defineComponent } from "vue";

const __default__ = defineComponent({
  name: "SliderComponent",
  props: {
    imageCount: {
      type: Number,
      default: 13,
    },
    animationDuration: {
      type: Number,
      default: 40,
    },
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "2d91b15c": (_ctx.animationDuration + "s")
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__