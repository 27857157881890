import { Module } from "vuex";
import { RootState } from "@/store/types";
import { getters } from "@/store/modules/features/getters";
import { mutations } from "@/store/modules/features/mutations";
import { actions } from "@/store/modules/features/actions";
import { FeaturesState } from "@/store/modules/features/types";

export const state: FeaturesState = {
  activeFeature: null,
};

export const toast: Module<FeaturesState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default toast;
