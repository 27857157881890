import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderComponent = _resolveComponent("HeaderComponent")!
  const _component_HeroComponent = _resolveComponent("HeroComponent")!
  const _component_WorkflowComponent = _resolveComponent("WorkflowComponent")!
  const _component_FeaturesComponent = _resolveComponent("FeaturesComponent")!
  const _component_DemoComponent = _resolveComponent("DemoComponent")!
  const _component_CTAComponent = _resolveComponent("CTAComponent")!
  const _component_FooterComponent = _resolveComponent("FooterComponent")!
  const _component_FeatureModal = _resolveComponent("FeatureModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("section", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args))),
      class: _normalizeClass([{ has__modal: _ctx.feature }, "base"])
    }, [
      _createVNode(_component_HeaderComponent),
      _createElementVNode("main", null, [
        _createVNode(_component_HeroComponent),
        _createVNode(_component_WorkflowComponent),
        _createVNode(_component_FeaturesComponent),
        _createVNode(_component_DemoComponent),
        _createVNode(_component_CTAComponent),
        _createVNode(_component_FooterComponent)
      ])
    ], 2),
    _createVNode(_component_FeatureModal, {
      onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
    })
  ], 64))
}