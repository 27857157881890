<template>
  <section class="features">
    <div class="features__container">
      <div class="features__container__titles">
        <h2>
          Email design, s<span class="i-1">i</span><span class="i-2">i</span
          ><span class="i-3">i</span><span class="i-4">i</span
          ><span class="i-5">i</span>implified
        </h2>
        <p>
          Save time and maximise effort with features designed to 10x your email
          design capabilities for a fraction of the time
        </p>
      </div>
      <div class="features__list">
        <div
          v-for="(feature, key) in features"
          :key="key"
          class="features__list__item"
          :class="{ closed: activeFeature === null }"
          @click.stop="setActiveFeature(feature)"
        >
          <h3>
            {{ feature.title }}
          </h3>
          <button>
            <BaseIcon icon="home/features/show" />
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted } from "vue";
import BaseIcon from "@/components/icon/BaseIcon.vue";
import store from "@/store";

export default defineComponent({
  name: "FeaturesComponent",
  components: { BaseIcon },

  setup() {
    const features = [
      {
        icon: "modular",
        title: "Modular Components",
        description:
          "Never start from scratch. 100+ predesigned email components to help you hit the ground running. Simply select and edit.",
      },
      {
        icon: "editor",
        title: "No-code editor",
        description:
          "Edit your way. With Drawberry's flexible editing capabilities, adjust your emails to suit your brand and audience.",
      },
      {
        icon: "preview",
        title: "Email preview",
        description:
          "See what your audience gets in their inbox. Preview on desktop and mobile breakpoints or send to an email client. No breaks, no surprises.",
      },
      {
        icon: "collaboration",
        title: "Easy collaboration",
        description:
          "Collaboration made easy. Share your designs with anyone with a single link. No account required!",
      },
      {
        icon: "directory",
        title: "Projects directory",
        description:
          "A home for your work. Organise your designs in one place. Find projects via search.",
      },
      {
        icon: "exports",
        title: "Unlimited exports",
        description:
          "Connect to our growing list of ESPs (MailChimp, Gmail, HubSpot etc.) and export as many as you need—ready to go.",
      },
    ];

    onMounted(() => {
      store.commit("features/SET_ACTIVE_FEATURE", null);
    });

    const activeFeature = computed(() => {
      return store.getters["features/activeFeature"];
    });

    const setActiveFeature = (feat: any) => {
      if (activeFeature.value) {
        store.commit("features/SET_ACTIVE_FEATURE", null);
        return;
      }
      store.commit("features/SET_ACTIVE_FEATURE", feat);
    };

    return {
      activeFeature,
      features,
      setActiveFeature,
    };
  },
});
</script>

<style scoped></style>
