<template>
  <section @click="closeModal" :class="{ has__modal: feature }" class="base">
    <HeaderComponent />

    <main>
      <HeroComponent />
      <WorkflowComponent />
      <FeaturesComponent />

      <DemoComponent />
      <CTAComponent />
      <FooterComponent />
    </main>
  </section>
  <FeatureModal @click.stop />
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import HeaderComponent from "@/components/home/HeaderComponent.vue";
import HeroComponent from "@/components/home/HeroComponent.vue";
import WorkflowComponent from "@/components/home/WorkflowComponent.vue";
import FeaturesComponent from "@/components/home/FeaturesComponent.vue";
import FooterComponent from "@/components/home/FooterComponent.vue";
import CTAComponent from "@/components/home/CTAComponent.vue";
import DemoComponent from "@/components/home/DemoComponent.vue";
import FeatureModal from "@/components/modals/FeatureModal.vue";
import store from "@/store";

export default defineComponent({
  name: "HomeView",
  components: {
    FeatureModal,
    DemoComponent,
    CTAComponent,
    FooterComponent,
    FeaturesComponent,
    WorkflowComponent,
    HeroComponent,
    HeaderComponent,
  },

  setup() {
    const feature = computed(() => {
      return store.getters["features/activeFeature"];
    });

    const closeModal = () => {
      if (!feature.value) return;
      store.commit("features/SET_ACTIVE_FEATURE", null);
    };

    return { feature, closeModal };
  },
});
</script>
