<template>
  <section class="cta">
    <div class="cta__first">
      <h2>Be the first to try</h2>
      <BaseIcon icon="logo-beta-dark" />
    </div>
    <div class="cta__second">
      <a href="">Get early access</a>
      <p>We’re glad to have you! 😊</p>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import BaseIcon from "@/components/icon/BaseIcon.vue";

export default defineComponent({
  name: "CTAComponent",
  components: { BaseIcon },
});
</script>

<style scoped></style>
