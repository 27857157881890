import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cta" }
const _hoisted_2 = { class: "cta__first" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("h2", null, "Be the first to try", -1)),
      _createVNode(_component_BaseIcon, { icon: "logo-beta-dark" })
    ]),
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "cta__second" }, [
      _createElementVNode("a", { href: "" }, "Get early access"),
      _createElementVNode("p", null, "We’re glad to have you! 😊")
    ], -1))
  ]))
}