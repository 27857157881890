import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "modal"
}
const _hoisted_2 = { class: "modal__header" }
const _hoisted_3 = { class: "modal__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_ctx.feature)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.cancel && _ctx.cancel(...args)))
          }, [
            _createVNode(_component_BaseIcon, { icon: "cancel" })
          ])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_BaseIcon, {
            icon: `features/${_ctx.feature.icon}`
          }, null, 8, ["icon"]),
          _createElementVNode("h1", null, _toDisplayString(_ctx.feature.title), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.feature.description), 1)
        ])
      ]))
    : _createCommentVNode("", true)
}