<template>
  <header class="header">
    <div class="header__container">
      <BaseIcon class="header__logo" icon="logo-beta" />
      <div class="header__actions">
        <a href="" class="header__actions__guide">Product guide</a>
        <a href="" class="header__actions__access">Get early access</a>
      </div>
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import BaseIcon from "@/components/icon/BaseIcon.vue";

export default defineComponent({
  name: "HeaderComponent",
  components: { BaseIcon },
});
</script>

<style scoped></style>
