import { createStore } from "vuex";
// import createPersistedState from "vuex-persistedstate";
import features from "@/store/modules/features";

export default createStore({
  state: {
    data: "new",
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: { features },
  // plugins: [
  //   createPersistedState({
  //     storage: {
  //       getItem: (key: string) => localStorage.getItem(key),
  //       setItem: (key: string, state: string) =>
  //         localStorage.setItem(key, state),
  //       removeItem: (key: string) => localStorage.removeItem(key),
  //     },
  //   }),
  // ],
});
