import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "features" }
const _hoisted_2 = { class: "features__container" }
const _hoisted_3 = { class: "features__list" }
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createStaticVNode("<div class=\"features__container__titles\"><h2> Email design, s<span class=\"i-1\">i</span><span class=\"i-2\">i</span><span class=\"i-3\">i</span><span class=\"i-4\">i</span><span class=\"i-5\">i</span>implified </h2><p> Save time and maximise effort with features designed to 10x your email design capabilities for a fraction of the time </p></div>", 1)),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.features, (feature, key) => {
          return (_openBlock(), _createElementBlock("div", {
            key: key,
            class: _normalizeClass(["features__list__item", { closed: _ctx.activeFeature === null }]),
            onClick: _withModifiers(($event: any) => (_ctx.setActiveFeature(feature)), ["stop"])
          }, [
            _createElementVNode("h3", null, _toDisplayString(feature.title), 1),
            _createElementVNode("button", null, [
              _createVNode(_component_BaseIcon, { icon: "home/features/show" })
            ])
          ], 10, _hoisted_4))
        }), 128))
      ])
    ])
  ]))
}