import { createElementVNode as _createElementVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/home/workflow/create.png'
import _imports_1 from '@/assets/images/home/workflow/edit.png'
import _imports_2 from '@/assets/images/home/workflow/export.png'


const _hoisted_1 = { class: "workflow" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<div class=\"workflow__container\"><div class=\"workflow__container__item\"><h3>Create</h3><p>Drag ‘n drop components onto the canvas from our component panel</p><div class=\"workflow__container__img__box\"><img src=\"" + _imports_0 + "\" alt=\"\"></div></div><div class=\"workflow__container__item\"><h3>Edit</h3><p>Design and modify your email with our intuitive edit panel</p><div class=\"workflow__container__img__box\"><img src=\"" + _imports_1 + "\" alt=\"\"></div></div><div class=\"workflow__container__item\"><h3>Export</h3><p>Connect and export directly to MailChimp, Gmail, HubSpot, etc</p><div class=\"workflow__container__img__box\"><img src=\"" + _imports_2 + "\" alt=\"\"></div></div></div>", 1)
  ])))
}