import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "hero__slider" }
const _hoisted_2 = { class: "slider-track" }
const _hoisted_3 = { class: "slider-inner" }
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = {
  class: "slider-inner",
  "aria-hidden": "true"
}
const _hoisted_6 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.imageCount, (n) => {
          return (_openBlock(), _createElementBlock("div", {
            key: n,
            class: "slider-image-wrapper"
          }, [
            _createElementVNode("img", {
              src: require(`@/assets/images/home/hero/sliders/${n}.png`),
              alt: `Slide ${n}`,
              class: "slider-image"
            }, null, 8, _hoisted_4)
          ]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.imageCount, (n) => {
          return (_openBlock(), _createElementBlock("div", {
            key: `duplicate-${n}`,
            class: "slider-image-wrapper"
          }, [
            _createElementVNode("img", {
              src: require(`@/assets/images/home/hero/sliders/${n}.png`),
              alt: `Slide ${n}`,
              class: "slider-image"
            }, null, 8, _hoisted_6)
          ]))
        }), 128))
      ])
    ])
  ]))
}