<template>
  <section class="demo">
    <div class="demo__container">
      <div class="demo__container__titles">
        <h2>
          Your creative edge <br />
          on an intuitive canvas
        </h2>
        <p>
          Our no-code platform is built to give you creative freedom <br />
          with a simplified user experience.
        </p>
      </div>

      <div class="demo__container__video">
        <video
          controls
          autoplay
          loop
          muted
          src="@/assets/images/home/demo/canvas.mp4"
          class="video-player"
        ></video>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "DemoComponent",
});
</script>

<style scoped></style>
