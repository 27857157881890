<template>
  <section class="hero">
    <div class="hero__container">
      <div class="hero__content">
        <h1>Stand out in a cluttered inbox</h1>
        <p>
          Discover our easy-to-use tool for designing engaging emails for your
          marketing campaigns and newsletters.
        </p>
        <a href="">Get early access</a>
      </div>
      <SliderComponent />
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SliderComponent from "@/components/home/SliderComponent.vue";

export default defineComponent({
  name: "HeroComponent",
  components: { SliderComponent },
});
</script>

<style scoped></style>
