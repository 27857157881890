import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/home/demo/canvas.mp4'


const _hoisted_1 = { class: "demo" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("div", { class: "demo__container" }, [
      _createElementVNode("div", { class: "demo__container__titles" }, [
        _createElementVNode("h2", null, [
          _createTextVNode(" Your creative edge "),
          _createElementVNode("br"),
          _createTextVNode(" on an intuitive canvas ")
        ]),
        _createElementVNode("p", null, [
          _createTextVNode(" Our no-code platform is built to give you creative freedom "),
          _createElementVNode("br"),
          _createTextVNode(" with a simplified user experience. ")
        ])
      ]),
      _createElementVNode("div", { class: "demo__container__video" }, [
        _createElementVNode("video", {
          controls: "",
          autoplay: "",
          loop: "",
          muted: "",
          src: _imports_0,
          class: "video-player"
        })
      ])
    ], -1)
  ])))
}