<template>
  <div v-if="feature" class="modal">
    <div class="modal__header">
      <button @click="cancel"><BaseIcon icon="cancel" /></button>
    </div>
    <div class="modal__content">
      <BaseIcon :icon="`features/${feature.icon}`" />
      <h1>{{ feature.title }}</h1>
      <p>
        {{ feature.description }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import BaseIcon from "@/components/icon/BaseIcon.vue";
import store from "@/store";

export default defineComponent({
  name: "FeatureModal",
  components: { BaseIcon },

  setup() {
    const feature = computed(() => {
      return store.getters["features/activeFeature"];
    });

    const cancel = () => {
      store.commit("features/SET_ACTIVE_FEATURE", null);
    };

    return { feature, cancel };
  },
});
</script>

<style scoped></style>
