import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/home/footer/polaroid-cards.png'


const _hoisted_1 = { class: "footer" }
const _hoisted_2 = { class: "footer__container" }
const _hoisted_3 = { class: "footer__cta" }
const _hoisted_4 = { class: "footer__cta__actions" }
const _hoisted_5 = { class: "footer__imprint" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[1] || (_cache[1] = _createElementVNode("h6", null, "Let’s stay in touch", -1)),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("button", null, [
            _createVNode(_component_BaseIcon, { icon: "home/footer/cta/x" })
          ]),
          _createElementVNode("button", null, [
            _createVNode(_component_BaseIcon, { icon: "home/footer/cta/instagram" })
          ]),
          _createElementVNode("button", null, [
            _createVNode(_component_BaseIcon, { icon: "home/footer/cta/discord" })
          ]),
          _createElementVNode("button", null, [
            _createVNode(_component_BaseIcon, { icon: "home/footer/cta/slack" }),
            _cache[0] || (_cache[0] = _createElementVNode("span", null, [
              _createTextVNode("Join our Slack "),
              _createElementVNode("br"),
              _createTextVNode(" community")
            ], -1))
          ])
        ]),
        _cache[2] || (_cache[2] = _createElementVNode("p", null, [
          _createTextVNode("or send us an email at "),
          _createElementVNode("a", { href: "" }, "hello@drawberry.io")
        ], -1))
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_BaseIcon, { icon: "home/footer/berrycon" }),
        _cache[3] || (_cache[3] = _createElementVNode("h6", null, "Made with 💚 from the Berryverse™ 🌈", -1)),
        _cache[4] || (_cache[4] = _createElementVNode("p", null, "© 2024 Drawberry. All rights reserved", -1)),
        _cache[5] || (_cache[5] = _createElementVNode("div", { class: "footer__imprint__img__box" }, [
          _createElementVNode("img", {
            src: _imports_0,
            alt: ""
          })
        ], -1))
      ])
    ])
  ]))
}